import React, { useState, useEffect, useContext } from 'react';
import {
  Container, CardBody, Form, Card, Row, Col, Button, FormGroup,
  Tooltip, Input
} from 'reactstrap';
import DateFilter from 'views/examples/DateFilter';
import Select, { components } from 'react-select';
import { DataContext } from 'layouts/Admin';
import authHeader from 'services/auth-header';
import axios from 'axios';
import { baseUrl } from 'constants/url';
import sortArray from 'utils/sort';
import { FormControlLabel, Switch } from '@mui/material';
import LineImage from "../../assets/img/icons/lines_icon.png";
import RecipeImage from "../../assets/img/icons/Recipe_New1.png";
import BrandImage from "../../assets/img/icons/brandsMining.png";
import TierImage from "../../assets/img/icons/tiersMining.png";
import { FaInfoCircle } from "react-icons/fa";

const customStyles = {
  input: base => ({
    ...base,
    color: 'white'
  }),
  singleValue: base => ({
    ...base,
    color: 'white'
  }),
  placeholder: base => ({
    ...base,
    color: 'white'
  }),
  control: (base, state) => ({
    ...base,
    // background: "#023950",
    // background: "#737977",
    background: '#19312e',
    // match with the menu
    borderRadius: state.isFocused ? '3px 3px 0 0' : 3,
    // Overwrittes the different states of border
    // borderColor: state.isFocused ? "yellow" : "green",
    borderColor: state.isFocused ? '#a7ce39' : '#8898aa',
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    '&:hover': {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? '#a7ce39' : '#a7ce39'
    }
  }),
  menu: base => ({
    ...base,
    zIndex: 2000,
    // background: "#737977",
    background: '#19312e',
    color: 'white',
    // override border radius to match the box
    border: '1px solid #8898aa',
    // border: '1px solid #a7ce39',
    borderRadius: 5,
    // kill the gap
    marginTop: 0
  }),
  menuList: (base, state) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0
    // "::-webkit-scrollbar": {
    //   display: "none",
    // },
    // overflow: 'hidden'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      // backgroundColor: isFocused ? "#2462ad" : null,
      backgroundColor: isFocused ? '#a7ce39' : null,
      color: isFocused ? 'black' : 'white'
      // fontWeight: 'bold'
      // color: "#333333",
    };
  }
};

const DropdownIndicator = (props, image) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={image} width={25} alt='' />
    </components.DropdownIndicator>
  );
};

function Filters({ handleApplyFilters, filtersData, clientId }) {
  const [filterState, setFilterState] = useState({
    ...filtersData
  });
  const [lineData, setLineData] = useState("Loading...");
  const { lineData: linesDataResponse } = useContext(DataContext);
  const [recipeData, setRecipeData] = useState("Loading...");
  const [brandData, setBrandData] = useState("Loading...");
  const [tierData, setTierData] = useState("Loading...");
  const [tooltip, setTooltip] = useState(false);

  useEffect(() => {
    setFilterState({ ...filtersData });
  }, [filtersData]);

  const handleDateRange = (start_date, end_date) => {
    setFilterState(prevState => {
      return {
        ...prevState,
        start_date,
        end_date
      };
    });
  };


  useEffect(() => {
    if (linesDataResponse && linesDataResponse !== "Loading...") {
      if (linesDataResponse === "No Data Found")
        return setLineData(linesDataResponse);
      const option = linesDataResponse.map((item) => {
        return {
          label: item,
          value: item,
        };
      });
      option.unshift({ label: "All", value: "All" });
      setLineData(option);
    }
  }, [linesDataResponse]);

  useEffect(() => {

    axios
      .get(`${baseUrl}/api/admin/getBrands/?clientId=${clientId}`, {
        headers: authHeader(),
      })
      .then((res) => {
        let sortedData = sortArray(res.data.brands);

        const option = sortedData.map((item) => {
          return {
            label: item.brand,
            value: item.id,
          };
        });
        option.unshift({ label: "All", value: "All" });
        setBrandData(option);
      })
      .catch((err) => {
        setBrandData("No Data Found");
      });
    axios
      .get(`${baseUrl}/api/admin/getTiers/?clientId=${clientId}`, {
        headers: authHeader(),
      })
      .then((res) => {
        let sortedData = sortArray(res.data.tiers);

        const option = sortedData.map((item) => {
          return {
            label: item.tier,
            value: item.id,
          };
        });
        option.unshift({ label: "All", value: "All" });
        setTierData(option);
      })
      .catch((err) => {
        setTierData("No Data Found");
      });

    axios
      .get(`${baseUrl}/api/get_lookup_data/recipe/${clientId}`, {
        headers: authHeader(),
      })
      .then((res) => {
        let sortedData = sortArray(res.data.data);

        const option = sortedData.map((item) => {
          return {
            label: item,
            value: item,
          };
        });
        option.unshift({ label: "All", value: "All" });
        setRecipeData(option);
      })
      .catch((err) => {
        console.log(err);
        setRecipeData("No Data Found");
      });
  }, [clientId]);

  const handleApplyFromDateFilter = () => {
    handleApplyFilters(filterState);
  };



  useEffect(() => {
    if (
      filterState.line !== "Loading..." &&
      filterState.recipe !== "Loading..."
    )
      handleApplyFilters(filterState);
  }, [filterState.line, filterState.recipe]);

  const onChangeSelectValue = (value, name) => {
    console.log(name, value);
    setFilterState((prevState) => {
      return {
        ...prevState,
        [name.name]: value.value,
        searchByRecipe: "",
      };
    });
  };

  const onChangeText = (e) => {
    setFilterState((prevState) => {
      return {
        ...prevState,
        searchByRecipe: e.target.value,
      };
    });
  };


  const handleApply = () => {
    console.log("apply");
    handleApplyFilters(filterState);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleApplyFilters(filterState);
    }
  };

  const handleToggleSwitch = (event) => {
    setFilterState((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.checked,
      };
    });
  };

  return (
    <Card>
      <CardBody className='rounded px-5 py-4'>
        <Form>
          <div className=''>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={4}
                xxl={2}
                className='mb-3'
              >
                <DateFilter
                  filtersData={filtersData}
                  handleDateRange={handleDateRange}
                  handleApplyFromDateFilter={handleApplyFromDateFilter}
                />
              </Col>
              <Col xs={12} md={6} xl={4} xxl={2}>
                <FormGroup className="mb-2">
                  {/* <label className="form-control-label textWhite d-block filter-label-font-size">
                      Line
                    </label> */}
                  <div className="d-flex justify-content-between align-items-center">
                    <label className="form-control-label textWhite filter-label-font-size">
                      Line
                    </label>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={filterState.lineExclude}
                          onChange={handleToggleSwitch}
                          name="lineExclude"
                          color="primary"
                          size="small"
                        />
                      }
                      label="Exclude"
                    />
                  </div>
                  {lineData === "Loading..." ? (
                    lineData
                  ) : lineData === "No Data Found" ? (
                    lineData
                  ) : (
                    <Select
                      placeholder="Select Line..."
                      components={{
                        DropdownIndicator: (props) =>
                          DropdownIndicator(props, LineImage),
                      }}
                      name="line"
                      value={{ label: filterState.line }}
                      onChange={onChangeSelectValue}
                      styles={customStyles}
                      options={lineData}
                    />
                  )}
                </FormGroup>
              </Col>
              <Col xs={12} md={6} xl={4} xxl={2}>
                <FormGroup className="mb-2">
                  {/* <label className="form-control-label textWhite d-block filter-label-font-size">
                      Recipe
                    </label> */}
                  <div className="d-flex justify-content-between align-items-center">
                    <label className="form-control-label textWhite filter-label-font-size">
                      Recipe
                    </label>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={filterState.recipeExclude}
                          onChange={handleToggleSwitch}
                          name="recipeExclude"
                          color="primary"
                          size="small"
                        />
                      }
                      label="Exclude"
                    />
                  </div>
                  {recipeData === "Loading..." ? (
                    recipeData
                  ) : recipeData === "No Data Found" ? (
                    recipeData
                  ) : (
                    <Select
                      placeholder="Select Recipe..."
                      components={{
                        DropdownIndicator: (props) =>
                          DropdownIndicator(props, RecipeImage),
                      }}
                      name="recipe"
                      value={{ label: filterState.recipe }}
                      onChange={onChangeSelectValue}
                      styles={customStyles}
                      options={recipeData}
                    />
                  )}
                </FormGroup>
              </Col>
              <Col xs={12} md={6} xl={4} xxl={2}>
                <FormGroup className="mb-2">
                  {/* <label className="form-control-label textWhite d-block filter-label-font-size">
                      Search By Recipe
                      <FaInfoCircle
                        id="search-by-recipe-tooltip"
                        fontSize={"18px"}
                        className="ml-2"
                      />
                    </label> */}
                  <div className="d-flex justify-content-between align-items-center">
                    <label className="form-control-label textWhite filter-label-font-size">
                      Search By Recipe
                      <FaInfoCircle
                        id="search-by-recipe-tooltip"
                        fontSize={"18px"}
                        className="ml-2"
                      />
                    </label>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={filterState.searchByRecipeExclude}
                          onChange={handleToggleSwitch}
                          name="searchByRecipeExclude"
                          color="primary"
                          size="small"
                        />
                      }
                      label="Exclude"
                    />
                  </div>
                  <Tooltip
                    placement={"top"}
                    isOpen={tooltip}
                    target={`search-by-recipe-tooltip`}
                    toggle={() => {
                      setTooltip((st) => !st);
                    }}
                  >
                    Search by Recipe: Enter the initials or part of a recipe
                    name to find matching recipes. This search will fetch all
                    results that include the provided text in the recipe name.
                  </Tooltip>
                  <Input
                    value={filterState.searchByRecipe}
                    onChange={onChangeText}
                    name="searchByRecipe"
                    onKeyDown={handleKeyDown}
                    styles={customStyles}
                    className="textBg"
                  />
                </FormGroup>
              </Col>

              <Col xs={12} md={6} xl={4} xxl={2}>
                <FormGroup className="mb-3">
                  {/* <label className="form-control-label textWhite d-block filter-label-font-size">
                        Brand
                      </label> */}
                  <div className="d-flex justify-content-between align-items-center">
                    <label className="form-control-label textWhite filter-label-font-size">
                      Brand
                    </label>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={filterState.brandExclude}
                          onChange={handleToggleSwitch}
                          name="brandExclude"
                          color="primary"
                          size="small"
                        />
                      }
                      label="Exclude"
                    />
                  </div>
                  {brandData === "Loading..." ? (
                    brandData
                  ) : brandData === "No Data Found" ? (
                    brandData
                  ) : (
                    <Select
                      placeholder="Select Brand..."
                      components={{
                        DropdownIndicator: (props) =>
                          DropdownIndicator(props, BrandImage),
                      }}
                      name="brand"
                      value={{ label: filterState.brand }}
                      onChange={onChangeSelectValue}
                      styles={customStyles}
                      options={brandData}
                    />
                  )}
                </FormGroup>
              </Col>
              <Col xs={12} md={6} xl={4} xxl={2}>
                <FormGroup className="mb-3">
                  {/* <label className="form-control-label textWhite d-block filter-label-font-size">
                        Tier
                      </label> */}
                  <div className="d-flex justify-content-between align-items-center">
                    <label className="form-control-label textWhite filter-label-font-size">
                      Tier
                    </label>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={filterState.tierExclude}
                          onChange={handleToggleSwitch}
                          name="tierExclude"
                          color="primary"
                          size="small"
                        />
                      }
                      label="Exclude"
                    />
                  </div>
                  {tierData === "Loading..." ? (
                    tierData
                  ) : tierData === "No Data Found" ? (
                    tierData
                  ) : (
                    <Select
                      placeholder="Select Tier..."
                      components={{
                        DropdownIndicator: (props) =>
                          DropdownIndicator(props, TierImage),
                      }}
                      name="tier"
                      value={{ label: filterState.tier }}
                      onChange={onChangeSelectValue}
                      styles={customStyles}
                      options={tierData}
                    />
                  )}
                </FormGroup>
              </Col>
              <Col
                className='justify-content-end align-items-center d-flex pt-4 mb-3'
                xs={12}
                sm={12}
              // md={6}
              // lg={8} // 4
              // xl={8} // 4
              // xxl={10} // 2
              >
                <Button
                  style={{ backgroundColor: '#a7ce39', border: 'none' }}
                  onClick={() => handleApplyFilters(filterState)}
                >
                  Apply
                </Button>
                {/* <Tooltip title="Clear Filters" placement="top" arrow> */}
                <Button
                  className=''
                  color='secondary'
                  onClick={() => {
                    const today = new Date();
                    const oneMonthAgo = new Date();
                    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
                    oneMonthAgo.setDate(oneMonthAgo.getDate() - 1);

                    console.log('oneMonthAgo', oneMonthAgo);
                    const yesterday = new Date(today);

                    yesterday.setDate(yesterday.getDate() - 7);
                    setFilterState({
                      start_date: oneMonthAgo.toISOString(),
                      end_date: today.toISOString(),
                      recipe: 'All',
                      line: 'All',
                      kpiMin: 0,
                      kpiMax: 200
                      // gaMin: -9999,
                      // gaMax: 9999,
                    });
                    handleApplyFilters({
                      start_date: oneMonthAgo.toISOString(),
                      end_date: today.toISOString(),
                      recipe: 'All',
                      line: 'All',
                      kpiMin: 0,
                      kpiMax: 200
                      // gaMin: -9999,
                      // gaMax: 9999,
                    });
                  }}
                >
                  Clear
                  {/* <i className="fa fa-times" /> */}
                </Button>
                {/* </Tooltip> */}
              </Col>
            </Row>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
}

export default Filters;
