import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { baseUrl } from 'constants/url'
import deepEqual from 'utils/deepequal'
import OperatorsProductsGraph from './OperatorsProductsGraph'
import TopOperatorsBrands from './TopOperatorsBrands'

const Operators = ({ filtersData, clientId }) => {
  const [productsChartData, setProductsChartData] = useState(null);
  const [brandsChartData, setBrandsChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [productsChartData2, setProductsChartData2] = useState(null);
  const [brandsChartData2, setBrandsChartData2] = useState(null);
  const [isLoadingProducts2, setIsLoadingProducts2] = useState(false);
  const [isLoadingBrands2, setIsLoadingBrands2] = useState(false);
  const [filter, setFilter] = useState({ start_date: filtersData.start_date, end_date: filtersData.end_date });

  const [performanceKey, setPerformanceKey] = useState("ppm");
  const [performanceKey2, setPerformanceKey2] = useState("ppm");

  useEffect(() => {
    if (deepEqual({ start_date: filtersData.start_date, end_date: filtersData.end_date }, filter)) return;
    setFilter({ start_date: filtersData.start_date, end_date: filtersData.end_date });
  }, [filtersData, clientId]);

  useEffect(() => {
    setIsLoadingProducts2(true);
    setProductsChartData2([]);
    axios
      .get(`${baseUrl}/api/mining/top-operators-products2`, {
        params: {
          ...filter, clientId, performanceKey, brand: filtersData.brand, tier: filtersData.tier,
          brandExclude: filtersData.brandExclude, tierExclude: filtersData.tierExclude
        }
      })
      .then((res) => {
        console.log("res", res);
        setProductsChartData2(res.data.products_data);
        setIsLoadingProducts2(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoadingProducts2(false);
        setProductsChartData2([]);
      });

  }, [filter, clientId, performanceKey, filtersData.brand, filtersData.tier]);

  useEffect(() => {
    setIsLoadingBrands2(true);
    setBrandsChartData2([]);
    axios
      .get(`${baseUrl}/api/mining/top-operators-brands2`, {
        params: {
          ...filter, clientId, performanceKey: performanceKey2, brand: filtersData.brand, tier: filtersData.tier,
          brandExclude: filtersData.brandExclude, tierExclude: filtersData.tierExclude
        },
      })
      .then((res) => {
        console.log("res", res);
        setBrandsChartData2(res.data.brands_data);
        setIsLoadingBrands2(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoadingBrands2(false);
        setBrandsChartData2([]);
      });
  }, [filter, clientId, performanceKey2, filtersData.brand, filtersData.tier]);


  return (
    <div>
      {/* <OperatorsGraph
        title={"Operators Packs Per Minute by Product"}
        topValues={productsChartData}
        isLoading={isLoading}
        performanceKey ={performanceKey }
        setPerformanceKey ={setPerformanceKey }
      /> */}
      <OperatorsProductsGraph
        title={" Operator Performance by Product"}
        topValues={productsChartData2}
        isLoading={isLoadingProducts2}
        performanceKey={performanceKey}
        setPerformanceKey={setPerformanceKey}
      />
      {/* <OperatorsGraph
        title={"Operators Packs Per Minute by Brand"}
        topValues={brandsChartData}
        isLoading={isLoading}
        performanceKey ={performanceKey }
        setPerformanceKey ={setPerformanceKey }
      /> */}
      <TopOperatorsBrands
        title={" Operator Performance by Brand"}
        topValues={brandsChartData2}
        isLoading={isLoadingBrands2}
        performanceKey={performanceKey2}
        setPerformanceKey={setPerformanceKey2}
      />
    </div>
  )
}

export default Operators