import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Progress,
  Button,
  FormGroup,
  Input,
} from "reactstrap";
import Table from "views/shared/Table";
import { TablePagination } from "@material-ui/core";
import axios from "axios";
import { baseUrl } from "constants/url";
import authHeader from "services/auth-header";
import { Skeleton } from "@mui/material";
import { toast } from "react-toastify";
import { useAuth } from "contexts/AuthContext";

function BatchesData({
  filtersData,
  clientId,
  clientSubscription,
  title = "Production Data",
  fetchReports
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);
  const { user } = useAuth();

  const fetchData = useCallback(() => {
    setIsLoading(true);
    axios
      .get(`${baseUrl}/api/dashboard/production_data/${page}/${rowsPerPage}`, {
        params: { ...filtersData, clientId },
        headers: authHeader(),
      })
      .then((res) => {
        setTableData(res.data.formattedEndData);
        setCount(res.data.count);
      })
      .catch((err) => {
        console.error(err);
        setTableData([]);
        setCount(0);
      })
      .finally(() => setIsLoading(false));
  }, [page, rowsPerPage, filtersData, clientId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleReport = useCallback(async () => {
    try {
      const batches = selectedAll
        ? tableData.map((item) => item.Batch_ID)
        : selectedItems.map((item) => item.Batch_ID);

      const res = await axios.post(
        `${baseUrl}/api/reports/batches-data-report`,
        { batches },
        {
          params: { clientId, userId: user.id },
          headers: authHeader(),
        }
      );

      if (res.data.isLargeReport) {
        setSelectedItems([]);
        setSelectedAll(false);
        toast.success(res.data.message);
      }

      if (res.data.reportUrl) {
        const link = document.createElement("a");
        link.href = res.data.reportUrl;
        link.setAttribute(
          "download",
          `Batch_Report_${new Date().toISOString()}.xlsx`
        );
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setSelectedItems([]);
        setSelectedAll(false);
        fetchReports()
      }
    } catch (err) {
      console.error("Error generating report:", err);
    }
  }, [selectedAll, selectedItems, tableData, clientId]);

  const handleSelectItems = useCallback(
    (check, item) => {
      if (check) {
        if (item === "all") {
          setSelectedItems([...tableData]);
          setSelectedAll(true);
        } else {
          setSelectedItems((prev) =>
            prev.find((r) => r.Batch_ID === item.Batch_ID)
              ? prev
              : [...prev, item]
          );
        }
      } else {
        if (item === "all") {
          setSelectedItems([]);
          setSelectedAll(false);
        } else {
          setSelectedItems((prev) =>
            prev.filter((r) => r.Batch_ID !== item.Batch_ID)
          );
        }
      }
    },
    [tableData]
  );

  const renderKPI = useCallback(
    (record) => (
      <div className="d-flex align-items-center">
        <Progress
          className="textBg"
          max="100"
          value={record.KPI.toString()}
          barClassName="bg-info"
          style={{ height: "20px" }}
        >
          <span className="progress-bg font-weight-bold">{record.KPI}%</span>
        </Progress>
      </div>
    ),
    []
  );

  const baseColumns = useMemo(
    () => [
      {
        id: "checkbox",
        title: (
          <FormGroup className="ml-3 mb-4" check>
            <Input
              type="checkbox"
              checked={selectedAll}
              onChange={(e) => handleSelectItems(e.target.checked, "all")}
              style={{ transform: "scale(1.5)" }}
            />
          </FormGroup>
        ),
        className: "sort table-data-font",
        render: (value) => (
          <FormGroup className="mb-4 ml-3" check>
            <Input
              type="checkbox"
              style={{ cursor: "pointer", transform: "scale(1.5)" }}
              checked={
                selectedAll ||
                selectedItems.some((item) => item.Batch_ID === value.Batch_ID)
              }
              onChange={(e) => {
                e.stopPropagation();
                handleSelectItems(e.target.checked, value);
              }}
            />
          </FormGroup>
        ),
      },
      {
        id:
          filtersData.end_date.includes("2020") ||
            filtersData.end_date.includes("2021")
            ? "TIMESTAMP"
            : "Timestamp",
        title: "End Time",
        className: "sort table-data-font",
      },
      {
        id: "Line",
        title: "Line",
        className: "sort table-data-font",
      },
      {
        id: "Recipe",
        title: "Recipe",
        className: "sort table-data-font",
      },
      {
        id: "Run_Time",
        title: "Runtime (Mins)",
        className: "sort table-data-font",
      },
      {
        id: "Total_Packs",
        title: "Packs",
        className: "sort table-data-font",
      },
      {
        id: "Avg_Speed",
        title: "Avg Speed",
        className: "sort table-data-font",
      },
      {
        id: "KPI",
        title: "KPI",
        className: "sort table-data-font",
        render: renderKPI,
      },
      {
        id: "Total_Wght",
        title: "Weight",
        className: "sort table-data-font",
      },
      {
        id: "Operator_Speed",
        title: "Operator Speed",
        className: "sort table-data-font",
      },
      {
        id: "Pack_Cost",
        title: "Cost/pack",
        className: "sort text-right table-data-font",
        render: (record) => `${record.Pack_Cost} £`,
      },
    ],
    [
      selectedAll,
      selectedItems,
      handleSelectItems,
      filtersData.end_date,
      renderKPI,
    ]
  );

  const premiumColumns = useMemo(
    () => [
      {
        id: "Rcp_Product",
        title: "Product",
        className: "sort custom-padding table-data-font",
      },
      {
        id: "Rcp_Brand",
        title: "Brand",
        className: "sort custom-padding table-data-font",
      },
      {
        id: "Rcp_Tier",
        title: "Tier",
        className: "sort custom-padding table-data-font",
      },
    ],
    []
  );

  const tableColumns = useMemo(
    () =>
      clientSubscription === "premium"
        ? [...baseColumns, ...premiumColumns]
        : baseColumns,
    [clientSubscription, baseColumns, premiumColumns]
  );

  const tableClasses = useMemo(
    () => ({
      table:
        "table align-items-center table-flush table-sm border-bottom text-white table-custom",
      thead: "",
      tbody: "list table-td-color",
    }),
    []
  );

  return (
    <Container fluid>
      <Row>
        <Col xs={12} className="mb-4">
          <Card className="shadow h-100">
            <h2 className="px-4 pt-4 text-color-mw d-flex justify-content-between">
              {title}
              {selectedItems.length > 0 && (
                <Button
                  style={{
                    backgroundColor: "#a7ce39",
                    border: "none",
                    height: "33px",
                    width: "fit-content",
                  }}
                  className="px-4"
                  size="sm"
                  onClick={handleReport}
                >
                  Get Report for Selected
                  <i className="fa fa-plus ml-2" />
                </Button>
              )}
            </h2>

            <>
              <Table
                values={tableData}
                columns={tableColumns}
                tableClasses={tableClasses}
                isLoading={isLoading}
                handleSelected={handleSelectItems}
                selected={selectedItems}
              />

              {isLoading ? (
                <div className="d-flex justify-content-end gap-3 mr-3">
                  <Skeleton variant="rectangular" width={150} />
                  <Skeleton variant="rectangular" width={100} />
                </div>
              ) : (
                <TablePagination
                  component="div"
                  className="text-muted"
                  count={count}
                  page={page}
                  onPageChange={(event, newPage) => setPage(newPage)}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={(event) =>
                    setRowsPerPage(parseInt(event.target.value, 10))
                  }
                />
              )}
            </>
            {!isLoading && tableData.length === 0 && (
              <span className="m-4">No Data Found</span>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default React.memo(BatchesData);
