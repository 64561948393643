import React, { useState, useEffect } from "react";
import { Col } from "reactstrap";
import axios from "axios";
import { baseUrl } from "constants/url";
import { Grid } from "@material-ui/core";
import authHeader from "../../services/auth-header";
import PacksProducedImage from "../../assets/img/icons/packs_produced_icon_w.png";
import KpiImage from "../../assets/img/icons/kpi_icon_w.png";
import AverageSpeedImage from "../../assets/img/icons/average_speeds_icon.png";
import AverageGiveAwayImage from "../../assets/img/icons/average_giveaway_icon.png";
import GiveAwayPercentage from "../../assets/img/icons/Giveaway-percentage-icon.png";
import PpmPerOperator from "../../assets/img/icons/packs-per-minute-per-operator-icon.png";
import WpmPerOperator from "../../assets/img/icons/weight-per-minute-per-operator-icon.png";
import WeightProducedIcon from "../../assets/img/icons/weight-produced-icon.png";
import deepEqual from "utils/deepequal";
import DataCard from "./DataCard";
import CustomizedCardData from "./CustomizedCardData";

const initValue = {
  durationDays: null,
  totalPacks: {
    count: null,
    sinceValue: "",
  },
  kpi: {
    count: null,
    sinceValue: "",
  },
  weightProduced: {
    count: null,
    sinceValue: "",
  },
  totalGa: {
    count: null,
    sinceValue: "",
  },
  packsPerMinutePerOperator: {
    count: null,
    sinceValue: "",
  },
  weightPerMinutePerOperator: {
    count: null,
    sinceValue: "",
  },
  packsPerMinutePerOperatorWithutZeros: {
    count: null,
    sinceValue: "",
  },
  weightPerMinutePerOperatorWithoutZeros: {
    count: null,
    sinceValue: "",
  },
  productionTime: {
    count: null,
    sinceValue: "",
  },
  gaPercentage: {
    count: null,
    sinceValue: "",
  },
};

const Header = ({ filtersData, clientId }) => {
  const [filters, setFilters] = useState();
  const [message, setMessage] = useState("");
  const [cardsData, setCardsData] = useState(initValue);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (deepEqual(filtersData, filters)) return;
    setFilters(filtersData);
    setMessage("");
    setIsLoading(true);
    axios
      .get(`${baseUrl}/api/dashboard/cards_data`, {
        params: { ...filtersData, clientId },
        headers: authHeader(),
      })
      .then((res) => {
        console.log(res);
        if (res.data.message === "No data found") {
          setMessage(res.data.message);
          setCardsData(initValue);
        } else {
          setMessage("");
          setCardsData(res.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [filtersData, clientId]);

  return (
    <>
      <Col
        xs={
          filtersData.line &&
          filtersData.line !== "All" &&
          !filtersData.lineExclude
            ? "12"
            : "12"
        }
        md={
          filtersData.line &&
          filtersData.line !== "All" &&
          !filtersData.lineExclude
            ? "6"
            : "4"
        }
        className="mb-3"
      >
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          style={{ minHeight: "100%" }}
          spacing={1}
        >
          <Grid item xs={12} style={{ width: "100%" }}>
            <DataCard
              isLoading={isLoading}
              message={message}
              cardImage={PacksProducedImage}
              cardData={{
                title: "Packs Produced",
                durationDays: cardsData.durationDays,
                count: cardsData.totalPacks.count,
                sinceValue: cardsData.totalPacks.sinceValue,
              }}
              toolTipData={{
                id: "packs-card-tooltip",
                message: "Total Packs Produced against the selected filters",
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ width: "100%" }}>
            <DataCard
              isLoading={isLoading}
              message={message}
              cardImage={KpiImage}
              cardData={{
                title: "KPI",
                durationDays: cardsData.durationDays,
                count: cardsData.kpi.count,
                sinceValue: cardsData.kpi.sinceValue,
              }}
              toolTipData={{
                id: "kpi-card-tooltip",
                message: "Average KPI against the selected filters",
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ width: "100%" }}>
            <DataCard
              isLoading={isLoading}
              message={message}
              cardImage={WeightProducedIcon}
              cardData={{
                title: "Weight Produced",
                durationDays: cardsData.durationDays,
                count: cardsData.weightProduced.count,
                sinceValue: cardsData.weightProduced.sinceValue,
              }}
              toolTipData={{
                id: "wp-card-tooltip",
                message:
                  "Total Weight Produced in the selected time period (in Kg)",
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ width: "100%" }}>
            <CustomizedCardData
              isLoading={isLoading}
              message={message}
              cardImage={PpmPerOperator}
              cardData={{
                title: "Packs Per Minute Per Operator",
                durationDays: cardsData.durationDays,
                count: Math.ceil(cardsData.packsPerMinutePerOperator.count),
                sinceValue: cardsData.packsPerMinutePerOperator.sinceValue,
                zeroCount: Math.ceil(
                  cardsData.packsPerMinutePerOperatorWithutZeros.count
                ),
                zeroSinceValue:
                  cardsData.packsPerMinutePerOperatorWithutZeros.sinceValue,
              }}
              toolTipData={{
                id: "ppmpo-card-tooltip",
                message:
                  "Average Number of Packs Packed an operator in a minute",
              }}
            />
          </Grid>
        </Grid>
      </Col>
      <Col
        xs={
          filtersData.line &&
          filtersData.line !== "All" &&
          !filtersData.lineExclude
            ? "12"
            : "12"
        }
        md={
          filtersData.line &&
          filtersData.line !== "All" &&
          !filtersData.lineExclude
            ? "6"
            : "4"
        }
        className="mb-3"
      >
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          style={{ minHeight: "100%" }}
          spacing={1}
        >
          <Grid item xs={12} style={{ width: "100%" }}>
            <DataCard
              isLoading={isLoading}
              message={message}
              cardImage={AverageGiveAwayImage}
              cardData={{
                title: "Total Give Away",
                durationDays: cardsData.durationDays,
                count: (cardsData.totalGa.count / 1000).toFixed(2),
                sinceValue: cardsData.totalGa.sinceValue,
              }}
              toolTipData={{
                id: "tga-card-tooltip",
                message: "Give Away against the filter (in Kg)",
              }}
              revert_order={true}
            />
          </Grid>
          <Grid item xs={12} style={{ width: "100%" }}>
            <DataCard
              revert_order={true}
              isLoading={isLoading}
              message={message}
              cardImage={GiveAwayPercentage}
              cardData={{
                title: "Give Away Percentage",
                durationDays: cardsData.durationDays,
                count: cardsData.gaPercentage.count,
                sinceValue: cardsData.gaPercentage.sinceValue,
              }}
              toolTipData={{
                id: "gap-card-tooltip",
                message: "Percentage of Give Away against the Total Tonnage",
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ width: "100%" }}>
            <DataCard
              isLoading={isLoading}
              message={message}
              cardImage={AverageSpeedImage}
              cardData={{
                title: "Production Time In Minutes",
                durationDays: cardsData.durationDays,
                count: cardsData.productionTime.count,
                sinceValue: cardsData.productionTime.sinceValue,
              }}
              toolTipData={{
                id: "ptim-card-tooltip",
                message: "Number of active minutes in selected time period",
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ width: "100%" }}>
            <CustomizedCardData
              isLoading={isLoading}
              message={message}
              cardImage={WpmPerOperator}
              cardData={{
                title: "Weight Per Minute Per Operator",
                durationDays: cardsData.durationDays,
                count: Math.ceil(cardsData.weightPerMinutePerOperator.count),
                sinceValue: cardsData.weightPerMinutePerOperator.sinceValue,
                zeroCount: Math.ceil(
                  cardsData.weightPerMinutePerOperatorWithoutZeros.count
                ),
                zeroSinceValue:
                  cardsData.weightPerMinutePerOperatorWithoutZeros.sinceValue,
              }}
              toolTipData={{
                id: "wpmpo-card-tooltip",
                message:
                  "Average Weight Packed an operator in a minute (in KG)",
              }}
            />
          </Grid>
        </Grid>
      </Col>
    </>
  );
};

export default Header;
