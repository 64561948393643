// import { Skeleton } from "@mui/material";
// import React, { useState } from "react";
// import { FaInfoCircle } from "react-icons/fa";
// import {
//   Card,
//   CardBody,
//   CardTitle,
//   Row,
//   Col,
//   Spinner,
//   Tooltip,
// } from "reactstrap";

// function CustomizedCardData({
//   isLoading,
//   cardImage,
//   cardData,
//   message,
//   toolTipData,
//   revert_order = false,
// }) {
//   const [tooltip, setTooltip] = useState(false);
//   const [tooltip2, setTooltip2] = useState(false);

//   return (
//     <Card className="card-stats w-100" style={{ height: "100%" }}>
//       <CardBody className="">
//         <Row>
//           <Col>
//             <CardTitle tag="h3" className="text-color-mw my-0">
//               {cardData.title}
//               {/* <FaInfoCircle
//                 id={toolTipData.id}
//                 fontSize={"18px"}
//                 className="ml-2"
//               />
//               <Tooltip
//                 placement={"top"}
//                 isOpen={tooltip}
//                 target={toolTipData.id}
//                 toggle={() => {
//                   !isLoading && setTooltip((st) => !st);
//                 }}
//               >
//                 {toolTipData.message}
//               </Tooltip> */}
//             </CardTitle>
//             {message === "No data found" ? (
//               <span className="h4 text-muted font-weight-bold my-1">
//                 No Data Found
//               </span>
//             ) : isLoading ? (
//               <Skeleton width={100} height={50} />
//             ) : (
//               <Row>
//                 <Col>
//                   <span className="h3 text-white font-weight-bold my-1">
//                     {cardData.count}{" "}
//                     <FaInfoCircle
//                       id={`${toolTipData.id}-count-tooltip`}
//                       fontSize={"12px"}
//                       className="ml-2"
//                     />
//                     <Tooltip
//                       placement={"bottom"}
//                       isOpen={tooltip}
//                       target={`${toolTipData.id}-count-tooltip`}
//                       toggle={() => {
//                         setTooltip((st) => !st);
//                       }}
//                     >
//                       {toolTipData.message} with Idle time on Scales
//                     </Tooltip>
//                   </span>
//                   {isLoading && (
//                     <p
//                       className="mb-0 text-muted text-sm"
//                       style={{
//                         display: "flex",
//                         gap: "20px",
//                       }}
//                     >
//                       <span
//                         className={
//                           cardData.sinceValue.includes("decrease")
//                             ? revert_order
//                               ? "text-success mr-2"
//                               : "text-warning mr-2"
//                             : revert_order
//                             ? "text-warning mr-2"
//                             : "text-success mr-2"
//                         }
//                       >
//                         <Skeleton width={150} height={50} />
//                       </span>{" "}
//                       <span className="text-nowrap">
//                         <Skeleton width={150} height={50} />
//                       </span>
//                     </p>
//                   )}
//                   {!isLoading &&
//                     message !== "No data found" &&
//                     cardData.durationDays !== 0 && (
//                       <p className="mb-0 text-muted text-sm">
//                         <span
//                           className={
//                             cardData.sinceValue.includes("decrease")
//                               ? revert_order
//                                 ? "text-success mr-2"
//                                 : "text-warning mr-2"
//                               : revert_order
//                               ? "text-warning mr-2"
//                               : "text-success mr-2"
//                           }
//                         >
//                           <i
//                             className={
//                               cardData.sinceValue.includes("decrease")
//                                 ? "fas fa-arrow-down"
//                                 : "fa fa-arrow-up"
//                             }
//                           />{" "}
//                           {cardData.sinceValue}
//                         </span>{" "}
//                         <span className="text-nowrap">
//                           than previous {cardData.durationDays} days
//                         </span>
//                       </p>
//                     )}
//                 </Col>
//                 <Col>
//                   <span className="h3 text-white font-weight-bold my-1">
//                     {cardData.zeroCount}
//                     <FaInfoCircle
//                       id={`${toolTipData.id}-zero-count-tooltip`}
//                       fontSize={"12px"}
//                       className="ml-2"
//                     />
//                     <Tooltip
//                       placement={"bottom"}
//                       isOpen={tooltip2}
//                       target={`${toolTipData.id}-zero-count-tooltip`}
//                       toggle={() => {
//                         setTooltip2((st) => !st);
//                       }}
//                     >
//                       {toolTipData.message} ignoring Idle time on Scales
//                     </Tooltip>
//                   </span>
//                   {isLoading && (
//                     <p
//                       className="mb-0 text-muted text-sm"
//                       style={{
//                         display: "flex",
//                         gap: "20px",
//                       }}
//                     >
//                       <span
//                         className={
//                           cardData.sinceValue.includes("decrease")
//                             ? revert_order
//                               ? "text-success mr-2"
//                               : "text-warning mr-2"
//                             : revert_order
//                             ? "text-warning mr-2"
//                             : "text-success mr-2"
//                         }
//                       >
//                         <Skeleton width={150} height={50} />
//                       </span>{" "}
//                       <span className="text-nowrap">
//                         <Skeleton width={150} height={50} />
//                       </span>
//                     </p>
//                   )}
//                   {!isLoading &&
//                     message !== "No data found" &&
//                     cardData.durationDays !== 0 && (
//                       <p className="mb-0 text-muted text-sm">
//                         <span
//                           className={
//                             cardData.sinceValue.includes("decrease")
//                               ? revert_order
//                                 ? "text-success mr-2"
//                                 : "text-warning mr-2"
//                               : revert_order
//                               ? "text-warning mr-2"
//                               : "text-success mr-2"
//                           }
//                         >
//                           <i
//                             className={
//                               cardData.zeroSinceValue.includes("decrease")
//                                 ? "fas fa-arrow-down"
//                                 : "fa fa-arrow-up"
//                             }
//                           />{" "}
//                           {cardData.zeroSinceValue}
//                         </span>{" "}
//                         <span className="text-nowrap">
//                           than previous {cardData.durationDays} days
//                         </span>
//                       </p>
//                     )}
//                 </Col>
//               </Row>
//             )}
//           </Col>
//           <Col className="col-auto">
//             <div
//               className="icon icon-shape text-white rounded-circle shadow"
//               style={{
//                 backgroundColor: "#731e2f",
//                 width: "70px",
//                 height: "70px",
//               }}
//             >
//               <img src={cardImage} width={40} alt="" />
//             </div>
//           </Col>
//         </Row>
//       </CardBody>
//     </Card>
//   );
// }

// export default CustomizedCardData;

import React, { useState, memo } from "react";
import { Card, CardBody, Row, Col, Tooltip, CardTitle } from "reactstrap";
import { FaInfoCircle } from "react-icons/fa";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";

const STYLES = {
  cardIcon: {
    backgroundColor: "#731e2f",
    width: "70px",
    height: "70px",
  },
  flexContainer: {
    display: "flex",
    gap: "20px",
  },
};

const CSS_CLASSES = {
  success: "text-success mr-2",
  warning: "text-warning mr-2",
  arrowUp: "fa fa-arrow-up",
  arrowDown: "fas fa-arrow-down",
};

const CustomizedCardData = memo(
  ({
    cardData = {},
    cardImage,
    toolTipData = {},
    message = "",
    isLoading = false,
    revert_order = false,
  }) => {
    const [tooltip, setTooltip] = useState(false);
    const [tooltip2, setTooltip2] = useState(false);

    const getTextClass = (includesDecrease) => {
      if (includesDecrease) {
        return revert_order ? CSS_CLASSES.success : CSS_CLASSES.warning;
      }
      return revert_order ? CSS_CLASSES.warning : CSS_CLASSES.success;
    };

    const getArrowClass = (includesDecrease) => {
      return includesDecrease ? CSS_CLASSES.arrowDown : CSS_CLASSES.arrowUp;
    };

    const LoadingSkeleton = () => (
      <p className="mb-0 text-muted text-sm" style={STYLES.flexContainer}>
        <span
          className={getTextClass(cardData.sinceValue?.includes("decrease"))}
        >
          <Skeleton width={150} height={50} />
        </span>
        <span className="text-nowrap">
          <Skeleton width={150} height={50} />
        </span>
      </p>
    );

    const ComparisonData = ({ sinceValue, durationDays }) => (
      <p className="mb-0 text-muted text-sm">
        <span className={getTextClass(sinceValue?.includes("decrease"))}>
          <i className={getArrowClass(sinceValue?.includes("decrease"))} />{" "}
          {sinceValue}
        </span>{" "}
        <span className="text-nowrap">than previous {durationDays} days</span>
      </p>
    );

    const CountDisplay = ({
      count,
      tooltipId,
      tooltipState,
      setTooltipState,
      tooltipMessage,
    }) => (
      <span className="h3 text-white font-weight-bold my-1">
        {count}
        <FaInfoCircle id={tooltipId} fontSize={"12px"} className="ml-2" />
        <Tooltip
          placement="bottom"
          isOpen={tooltipState}
          target={tooltipId}
          toggle={() => setTooltipState((prev) => !prev)}
        >
          {tooltipMessage}
        </Tooltip>
      </span>
    );

    return (
      <Card className="card-stats mb-4 mb-xl-0">
        <CardBody>
          <Row>
            <Col>
              {" "}
              <CardTitle tag="h3" className="text-color-mw my-0">
                {cardData.title}{" "}
                <FaInfoCircle
                  id={toolTipData.id}
                  fontSize={"18px"}
                  className="ml-2"
                />
                <Tooltip
                  placement={"top"}
                  isOpen={tooltip}
                  target={toolTipData.id}
                  toggle={() => {
                    !isLoading && setTooltip((st) => !st);
                  }}
                >
                  {toolTipData.message}
                </Tooltip>{" "}
              </CardTitle>
              {message === "No data found" ? (
                <span className="h2 font-weight-bold mb-0 text-white">
                  No data found
                </span>
              ) : isLoading ? (
                <Skeleton width={100} height={50} />
              ) : (
                <Row>
                  <Col>
                    <CountDisplay
                      count={cardData.count}
                      tooltipId={`${toolTipData.id}-count-tooltip`}
                      tooltipState={tooltip}
                      setTooltipState={setTooltip}
                      tooltipMessage={`${toolTipData.message} with Idle time on Scales`}
                    />
                    {isLoading ? (
                      <LoadingSkeleton />
                    ) : (
                      message !== "No data found" &&
                      cardData.durationDays !== 0 && (
                        <ComparisonData
                          sinceValue={cardData.sinceValue}
                          durationDays={cardData.durationDays}
                        />
                      )
                    )}
                  </Col>
                  <Col>
                    <CountDisplay
                      count={cardData.zeroCount}
                      tooltipId={`${toolTipData.id}-zero-count-tooltip`}
                      tooltipState={tooltip2}
                      setTooltipState={setTooltip2}
                      tooltipMessage={`${toolTipData.message} ignoring Idle time on Scales`}
                    />
                    {isLoading ? (
                      <LoadingSkeleton />
                    ) : (
                      message !== "No data found" &&
                      cardData.durationDays !== 0 && (
                        <ComparisonData
                          sinceValue={cardData.zeroSinceValue}
                          durationDays={cardData.durationDays}
                        />
                      )
                    )}
                  </Col>
                </Row>
              )}
            </Col>
            <Col className="col-auto">
              <div
                className="icon icon-shape text-white rounded-circle shadow"
                style={STYLES.cardIcon}
              >
                <img src={cardImage} width={40} alt="" />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
);

CustomizedCardData.propTypes = {
  cardData: PropTypes.shape({
    count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    zeroCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sinceValue: PropTypes.string,
    zeroSinceValue: PropTypes.string,
    durationDays: PropTypes.number,
  }),
  cardImage: PropTypes.string.isRequired,
  toolTipData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
  }),
  message: PropTypes.string,
  isLoading: PropTypes.bool,
  revert_order: PropTypes.bool,
};

CustomizedCardData.displayName = "CustomizedCardData";

export default CustomizedCardData;
