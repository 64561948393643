import React, { useContext, useState } from "react";
// import Filters from "views/shared/Filters";
import Filters from "./Filters.jsx";
import { Container } from "reactstrap";
import { DataContext } from "layouts/Admin.js";
import OperatorsData from "./OperatorsData.jsx";
import LinesData from "./LinesData";
import BatchesData from "./BatchesData";
import RecentReports from "./RecentReports.jsx";
import { baseUrl } from "constants/url.js";
import axios from "axios";
import authHeader from "services/auth-header.js";

function Index({ clientId, clientSubscription }) {
  const { filtersData, setFiltersData, timeRange, setTimeRange } =
    useContext(DataContext);

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 7);

  const handleApplyFilters = (filterData) => {
    setFiltersData({ ...filterData });
  };

  const fetchReports = () => {
    setTableData("Loading...");
    axios
      .get(
        `${baseUrl}/api/reports/${filter.filtersData.page}/${filter.filtersData.rowsPerPage}`,
        {
          params: { clientId },
          headers: authHeader(),
        }
      )
      .then((res) => {
        console.log("res.data.reports", res.data.reports);
        setTableData(res.data.reports);
        setCount(res.data.totalCount);
      })
      .catch((err) => {
        console.log(err);
        setTableData([]);
      });
  }

  const [filter, setFilter] = useState({
    filtersData: {
      page: 1,
      rowsPerPage: 10,
    },
    clientId,
  });
  const [tableData, setTableData] = useState([]);
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = useState(0);

  return (
    <Container className="bg-transparent" fluid>
      <Filters
        filtersData={filtersData}
        handleApplyFilters={handleApplyFilters}
        clientId={clientId}
        clientSubscription={clientSubscription}
        timeRange={timeRange}
        setTimeRange={setTimeRange}
      />
      <div className="mt-4" />
      <BatchesData
        filtersData={filtersData}
        fetchReports={fetchReports}
        setFiltersData={setFiltersData}
        clientId={clientId}
        title="Batches Data"
      />
      <div className="mt-4" />
      <OperatorsData
        filtersData={filtersData}
        fetchReports={fetchReports}
        setFiltersData={setFiltersData}
        clientId={clientId}
        timeRange={timeRange}
        type="Operator"
        title="Operators Data"
      />
      <div className="mt-4" />
      <LinesData
        filtersData={filtersData}
        fetchReports={fetchReports}
        setFiltersData={setFiltersData}
        clientId={clientId}
        timeRange={timeRange}
        title="Lines Data"
      />
      <RecentReports clientId={clientId}
        filter={filter}
        setFilter={setFilter}
        tableData={tableData}
        setTableData={setTableData}
        count={count}
        setCount={setCount}
        fetchReports={fetchReports}
      />
      {/* <OperatorsData
        filtersData={filtersData}
        setFiltersData={setFiltersData}
        clientId={clientId}
        timeRange={timeRange}
        type='Line'
        title="Lines Data"
      /> */}
    </Container>
  );
}

export default Index;
