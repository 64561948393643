import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  FormGroup,
  Input,
} from "reactstrap";
import Table from "views/shared/Table";
import { TablePagination } from "@material-ui/core";
import axios from "axios";
import { baseUrl } from "constants/url";
import authHeader from "services/auth-header";
import { Skeleton } from "@mui/material";
import { toast } from "react-toastify";
import { useAuth } from "contexts/AuthContext";

function OperatorsData({ filtersData, clientId, fetchReports }) {
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);
  const { user } = useAuth();

  const fetchData = useCallback(() => {
    setIsLoading(true);
    axios
      .get(`${baseUrl}/api/reports/operators-data/${page}/${rowsPerPage}`, {
        params: { ...filtersData, clientId },
        headers: authHeader(),
      })
      .then((res) => {
        setTableData(res.data.operators_data);
        setCount(res.data.pagination.totalRecords);
      })
      .catch((err) => {
        console.error(err);
        setTableData([]);
        setCount(0);
      })
      .finally(() => setIsLoading(false));
  }, [page, rowsPerPage, filtersData, clientId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleReport = useCallback(() => {
    const operators = selectedAll ? tableData : selectedItems;

    axios
      .post(
        `${baseUrl}/api/reports/operators-data-report`,
        { operators },
        {
          params: { ...filtersData, clientId, userId: user.id },
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data.isLargeReport) {
          setSelectedItems([]);
          setSelectedAll(false);
          toast.success(res.data.message);
        }
        if (res.data.reportDetails.s3_url) {
          const link = document.createElement("a");
          link.href = res.data.reportDetails.s3_url;
          link.setAttribute(
            "download",
            `Operators_Report_${new Date().toISOString()}.xlsx`
          );
          link.setAttribute("target", "_blank");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setSelectedItems([]);
          setSelectedAll(false);
          fetchReports()

        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [selectedAll, selectedItems, tableData, filtersData, clientId]);

  const handleSelectItems = useCallback(
    (check, item) => {
      if (check) {
        if (item === "all") {
          setSelectedItems([...tableData]);
          setSelectedAll(true);
        } else {
          setSelectedItems((prev) =>
            prev.find((r) => r.Op_Name === item.Op_Name && r.date === item.date)
              ? prev
              : [...prev, item]
          );
        }
      } else {
        if (item === "all") {
          setSelectedItems([]);
          setSelectedAll(false);
        } else {
          setSelectedItems((prev) =>
            prev.filter(
              (r) => !(r.Op_Name === item.Op_Name && r.date === item.date)
            )
          );
        }
      }
    },
    [tableData]
  );

  const tableColumns = useMemo(
    () => [
      {
        id: "checkbox",
        title: (
          <FormGroup className="ml-3 mb-4" check>
            <Input
              type="checkbox"
              checked={selectedAll}
              onChange={(e) => handleSelectItems(e.target.checked, "all")}
              style={{ transform: "scale(1.5)" }}
            />
          </FormGroup>
        ),
        className: "sort table-data-font",
        render: (value) => (
          <FormGroup className="mb-4 ml-3" check>
            <Input
              type="checkbox"
              style={{ cursor: "pointer", transform: "scale(1.5)" }}
              checked={
                selectedAll ||
                selectedItems.some(
                  (item) =>
                    item.Op_Name === value.Op_Name && item.date === value.date
                )
              }
              onChange={(e) => {
                e.stopPropagation();
                handleSelectItems(e.target.checked, value);
              }}
            />
          </FormGroup>
        ),
      },
      {
        id: "date",
        title: "Date",
        className: "sort table-data-font",
      },
      {
        id: "Op_Name",
        title: "Operator",
        className: "sort table-data-font",
      },
      {
        id: "total_packs",
        title: "Packs Produced",
        className: "sort table-data-font",
      },
      {
        id: "total_weight",
        title: "Total Weight",
        className: "sort table-data-font",
      },
      {
        id: "production_time_mins",
        title: "Production Time (mins)",
        className: "sort table-data-font",
      },
    ],
    [selectedAll, selectedItems, handleSelectItems]
  );

  const tableClasses = useMemo(
    () => ({
      table:
        "table align-items-center table-flush table-sm border-bottom text-white table-custom",
      thead: "",
      tbody: "list table-td-color",
    }),
    []
  );

  return (
    <Container fluid>
      <Row>
        <Col xs={12} className="mb-4">
          <Card className="shadow h-100">
            <h2 className="px-4 pt-4 text-color-mw d-flex justify-content-between">
              Operators Data
              {selectedItems.length > 0 && (
                <Button
                  style={{
                    backgroundColor: "#a7ce39",
                    border: "none",
                    height: "33px",
                    width: "fit-content",
                  }}
                  className="px-4"
                  size="sm"
                  onClick={handleReport}
                >
                  Get Report for Selected
                  <i className="fa fa-plus ml-2" />
                </Button>
              )}
            </h2>

            <>
              <Table
                values={tableData}
                columns={tableColumns}
                tableClasses={tableClasses}
                isLoading={isLoading}
                handleSelected={handleSelectItems}
                selected={selectedItems}
              />

              {isLoading ? (
                <div className="d-flex justify-content-end gap-3 mr-3">
                  <Skeleton variant="rectangular" width={150} />
                  <Skeleton variant="rectangular" width={100} />
                </div>
              ) : (
                <TablePagination
                  component="div"
                  className="text-muted"
                  count={count}
                  page={page}
                  onPageChange={(event, newPage) => setPage(newPage)}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={(event) =>
                    setRowsPerPage(parseInt(event.target.value, 10))
                  }
                />
              )}
            </>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default React.memo(OperatorsData);
