import React, { useContext, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardFooter,
  Badge,
  Tooltip,
} from "reactstrap";
import OpPerformance from "./OpPerformance";
import HeatMapComponent from "./HeatMapComponent";
import TopOperators from "./TopOperators";
import OverallProgress from "./OverallProgress";
import OperatorsTable from "./OperatorsTable";
import FiltersOperatorPerformance from "views/shared/FiltersOperatorPerformance";
import { FaInfoCircle } from "react-icons/fa";
import { DataContext } from "layouts/Admin";
import OperatorsCostTable from "views/Mining/OperatorsCostTable";
import Operators from "views/Mining/Operators";

let today1 = new Date();
today1.setHours(23, 59, 59, 999);

let today2 = new Date();
today2.setDate(today2.getDate() - 7);
today2.setHours(0, 0, 0, 0);

function OperatorPerformance({ clientId }) {
  // const { filtersData, setFiltersData } = useContext(DataContext)

  const [filtersData, setFiltersData] = useState({
    // start_date: "2020-01-01T00:00:00Z",
    start_date: today2.toISOString(),
    // end_date: "2020-12-29T23:59:59Z",
    end_date: today1.toISOString(),
    recipe: "All",
    recipeExclude: false,
    line: "All",
    lineExclude: false,
    kpiMin: 0,
    kpiMax: 1000,
    searchByRecipe: "",
    searchByRecipeExclude: false,
    product: "All",
    productExclude: false,
    brand: "All",
    brandExclude: false,
    tier: "All",
    tierExclude: false,
  });

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [toolTipHeatMap, setToolTipHeatMap] = useState(false);
  const [toolTipOverallPerf, setToolTipOverallPerf] = useState(false);
  const [toolTipOpTable, setToolTipOpTable] = useState(false);

  const handleApplyFilters = (filterData) => {
    setFiltersData({ ...filterData });
  };

  return (
    <div className="header bg-transparent py-0 mt-4">
      <Container fluid>
        <div className="header-body">
          <Row className="mb-2">
            <Col xs="12" className="text-left mb-4">
              <FiltersOperatorPerformance
                filtersData={filtersData}
                handleApplyFilters={handleApplyFilters}
                clientId={clientId}
              />
              {/* <span className="h1" style={{ color: "#a7ce39" }}>
                Duration
              </span>
              <span className="h2 text-muted ml-5"> */}
              {/* {yesterday.toDateString()} */}
              {/* {filtersData.start_date.replace('T', " ---- ")} */}
              {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
              {/* {today.toDateString()} */}
              {/* {filtersData.end_date.replace('T', " ---- ")} */}
              {/* </span> */}
            </Col>
            <Col xs="12" className="mb-2">
              <Card className="card-stats p-4" style={{ height: "100%" }}>
                <CardTitle className="text-center h1 font-text-bolder text-white">
                  Top Operators
                </CardTitle>
                <CardBody className="p-0">
                  <OpPerformance
                    title="Operator Performance"
                    clientId={clientId}
                    timePeriod={filtersData}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col xs="12" md="12" className="mb-4">
              <Card
                className="card-stats bg-transparent"
                style={{ height: "100%" }}
              >
                <CardBody className="p-0 h-100" >
                  <OperatorsCostTable
                    clientId={clientId}
                    filtersData={filtersData}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col xs="12" md="12" className="mb-4">
              <Card
                className="card-stats bg-transparent"
                style={{ height: "100%", minHeight: '560px' }}
              >
                <CardBody className="p-0">
                  <Operators clientId={clientId} filtersData={filtersData} />
                </CardBody>
              </Card>
            </Col>
            <Col xs="12" md="7" className="mb-0">
              <Row className="mb-0">
                <Col xs="12" md="6" className="mb-2">
                  <Card className="card-stats p-4" style={{ height: "100%" }}>
                    <CardTitle className="text-center h1 text-white">
                      Efficiency Heat Map{" "}
                      <span className="h5 text-muted">Last 30 days</span>
                      <FaInfoCircle
                        id="efficiency-heatmap-tooltip"
                        fontSize={"18px"}
                        className="ml-2"
                      />
                      <Tooltip
                        placement={"top"}
                        isOpen={toolTipHeatMap}
                        target={`efficiency-heatmap-tooltip`}
                        toggle={() => {
                          setToolTipHeatMap((st) => !st);
                        }}
                      >
                        Each box represents one day - the brighter the color the
                        higher the productivity of that day
                      </Tooltip>
                    </CardTitle>
                    <CardBody className="p-0">
                      <Card className="card-stats" style={{ height: "100%" }}>
                        <CardBody className="p-0 mx-auto">
                          <HeatMapComponent
                            clientId={clientId}
                            timePeriod={filtersData}
                            setFiltersData={setFiltersData}
                          />
                        </CardBody>
                      </Card>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs="12" md="6" className="mb-2">
                  <Card className="card-stats p-4" style={{ height: "100%" }}>
                    <CardTitle className="text-center h1 text-white">
                      Overall Performance
                      <FaInfoCircle
                        id="overall-perf-tooltip"
                        fontSize={"18px"}
                        className="ml-2"
                      />
                      <Tooltip
                        placement={"top"}
                        isOpen={toolTipOverallPerf}
                        target={`overall-perf-tooltip`}
                        toggle={() => {
                          setToolTipOverallPerf((st) => !st);
                        }}
                      >
                        This number shows how good overall the operators in
                        whole selected time period
                      </Tooltip>
                    </CardTitle>
                    <CardBody className="p-0 mx-auto">
                      <OverallProgress
                        clientId={clientId}
                        timePeriod={filtersData}
                      />
                    </CardBody>
                    <CardTitle className="text-center text-muted mt-2">
                      Target Range: 70% - 80%
                    </CardTitle>
                  </Card>
                </Col>
                <Col xs="12" className="mb-0">
                  <Card
                    className="card-stats p-4 mb-0"
                    style={{ height: "100%" }}
                  >
                    <CardTitle className="text-center h1 font-text-bolder text-white">
                      Operators Performance
                      <FaInfoCircle
                        id="op-perf-tooltip"
                        fontSize={"18px"}
                        className="ml-2"
                      />
                      <Tooltip
                        placement={"top"}
                        isOpen={toolTipOpTable}
                        target={`op-perf-tooltip`}
                        toggle={() => {
                          setToolTipOpTable((st) => !st);
                        }}
                      >
                        Find the best and worst performing operators here
                      </Tooltip>
                    </CardTitle>
                    <CardBody className="p-0">
                      <OperatorsTable
                        clientId={clientId}
                        timePeriod={filtersData}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xs="12" md="5" className="mb-2">
              <Card className="card-stats p-4" style={{ height: "100%" }}>
                <CardTitle
                  id="tooltip-id"
                  className="text-center h1 font-text-bolder text-white"
                >
                  Top Operators <FaInfoCircle fontSize={"18px"} />
                </CardTitle>
                <Tooltip
                  placement={"top"}
                  isOpen={tooltipOpen}
                  target={`tooltip-id`}
                  toggle={() => {
                    setTooltipOpen((st) => !st);
                  }}
                >
                  Derived from operator’s packing speed, and considering both
                  the targeted speed specified for the recipe being packed and
                  the duration for which the operator sustained that speed.
                </Tooltip>
                <CardBody className="p-0">
                  <TopOperators clientId={clientId} timePeriod={filtersData} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default OperatorPerformance;
