import React, { useState, useEffect } from "react";
import {
  Container,
  CardBody,
  Form,
  Card,
  Row,
  Col,
  Button,
  FormGroup,
} from "reactstrap";
import DateFilter from "views/examples/DateFilter";
import axios from "axios";
import { baseUrl } from "../../constants/url";
import authHeader from "services/auth-header";
import Select, { components } from "react-select";
import ProductImage from "../../assets/img/icons/productsMining.png";
import BrandImage from "../../assets/img/icons/brandsMining.png";
import TierImage from "../../assets/img/icons/tiersMining.png";
import LineImage from "../../assets/img/icons/lines_icon.png";
import RecipeImage from "../../assets/img/icons/Recipe_New1.png";
import sortArray from "utils/sort";
import TimePickers from "views/examples/TimePickers";
import { FormControlLabel, Switch } from "@mui/material";

const customStyles = {
  input: (base) => ({
    ...base,
    color: "white",
  }),
  singleValue: (base) => ({
    ...base,
    color: "white",
  }),
  placeholder: (base) => ({
    ...base,
    color: "white",
  }),
  control: (base, state) => ({
    ...base,
    background: "#19312e",
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    borderColor: state.isFocused ? "#a7ce39" : "#8898aa",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "#a7ce39" : "#a7ce39",
    },
  }),
  menu: (base) => ({
    ...base,
    zIndex: 2000,
    background: "#19312e",
    color: "white",
    border: "1px solid #8898aa",
    borderRadius: 5,
    marginTop: 0,
  }),
  menuList: (base, state) => ({
    ...base,
    padding: 0,
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#a7ce39" : null,
      color: isFocused ? "black" : "white",
    };
  },
};

const DropdownIndicator = (props, image) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={image} width={25} alt="" />
    </components.DropdownIndicator>
  );
};

function Filters({
  handleApplyFilters,
  filtersData,
  clientId,
  clientSubscription,
  timeRange,
  setTimeRange,
}) {
  const [filterState, setFilterState] = useState({
    ...filtersData,
  });
  const [productData, setProductData] = useState("Loading...");
  const [brandData, setBrandData] = useState("Loading...");
  const [tierData, setTierData] = useState("Loading...");
  const [lineData, setLineData] = useState("Loading...");
  const [recipeData, setRecipeData] = useState("Loading...");

  useEffect(() => {
    setFilterState({ ...filtersData });
  }, [filtersData]);

  useEffect(() => {
    axios
      .get(`${baseUrl}/api/admin/getProducts/?clientId=${clientId}`, {
        headers: authHeader(),
      })
      .then((res) => {
        let sortedData = sortArray(res.data.products);

        const option = sortedData.map((item) => {
          return {
            label: item.product,
            value: item.id,
          };
        });
        option.unshift({ label: "All", value: "All" });
        setProductData(option);
      })
      .catch((err) => {
        setProductData("No Data Found");
      });
    axios
      .get(`${baseUrl}/api/admin/getBrands/?clientId=${clientId}`, {
        headers: authHeader(),
      })
      .then((res) => {
        let sortedData = sortArray(res.data.brands);

        const option = sortedData.map((item) => {
          return {
            label: item.brand,
            value: item.id,
          };
        });
        option.unshift({ label: "All", value: "All" });
        setBrandData(option);
      })
      .catch((err) => {
        setBrandData("No Data Found");
      });
    axios
      .get(`${baseUrl}/api/admin/getTiers/?clientId=${clientId}`, {
        headers: authHeader(),
      })
      .then((res) => {
        let sortedData = sortArray(res.data.tiers);

        const option = sortedData.map((item) => {
          return {
            label: item.tier,
            value: item.id,
          };
        });
        option.unshift({ label: "All", value: "All" });
        setTierData(option);
      })
      .catch((err) => {
        setTierData("No Data Found");
      });
    axios
      .get(`${baseUrl}/api/get_lookup_data/recipe/${clientId}`, {
        headers: authHeader(),
      })
      .then((res) => {
        let sortedData = sortArray(res.data.data);

        const option = sortedData.map((item) => {
          return {
            label: item,
            value: item,
          };
        });
        option.unshift({ label: "All", value: "All" });
        setRecipeData(option);
      })
      .catch((err) => {
        console.log(err);
        setRecipeData("No Data Found");
      });
    axios
      .get(`${baseUrl}/api/get_lookup_data/line/${clientId}`, {
        headers: authHeader(),
      })
      .then((res) => {
        const option = res.data.data.map((item) => {
          return {
            label: item,
            value: item,
          };
        });
        option.unshift({ label: "All", value: "All" });
        setLineData(option);
      })
      .catch((err) => {
        console.log(err);
        setLineData("No Data Found");
      });
  }, [clientId]);

  const onChangeSelectValue = (value, name) => {
    setFilterState((prevState) => {
      handleApplyFilters({
        ...prevState,
        [name.name]: value.label,
      });
      return {
        ...prevState,
        [name.name]: value.label,
      };
    });
  };

  const handleDateRange = (start_date, end_date) => {
    setFilterState((prevState) => {
      return {
        ...prevState,
        start_date,
        end_date,
      };
    });
  };

  const handleApplyFromDateFilter = () => {
    handleApplyFilters(filterState);
  };

  const handleApply = () => {
    handleApplyFilters(filterState);
  };

  const handleToggleSwitch = (event) => {
    setFilterState((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.checked,
      };
    });
  };

  return (
    <Card>
      <CardBody className="rounded px-5 py-4">
        <Form>
          <div className="">
            <Row>
              <Col xs={12} md={6} xl={4} xxl={2} className="mb-3">
                <DateFilter
                  filtersData={filtersData}
                  handleDateRange={handleDateRange}
                  handleApplyFromDateFilter={handleApplyFromDateFilter}
                />
              </Col>
              <Col xs={12} md={6} xl={4} xxl={2}>
                <FormGroup className="mb-3">
                  {/* <label className="form-control-label textWhite d-block filter-label-font-size">
                    Line
                  </label> */}
                  <div className="d-flex justify-content-between align-items-center">
                    <label className="form-control-label textWhite filter-label-font-size">
                      Line
                    </label>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={filterState.lineExclude}
                          onChange={handleToggleSwitch}
                          name="lineExclude"
                          color="primary"
                          size="small"
                        />
                      }
                      label="Exclude"
                    />
                  </div>
                  {lineData === "Loading..." ? (
                    lineData
                  ) : lineData === "No Data Found" ? (
                    lineData
                  ) : (
                    <Select
                      placeholder="Select Line..."
                      components={{
                        DropdownIndicator: (props) =>
                          DropdownIndicator(props, LineImage),
                      }}
                      name="line"
                      value={{ label: filterState.line }}
                      onChange={onChangeSelectValue}
                      styles={customStyles}
                      options={lineData}
                    />
                  )}
                </FormGroup>
              </Col>
              <Col xs={12} md={6} xl={4} xxl={2}>
                <FormGroup className="mb-3">
                  {/* <label className="form-control-label textWhite d-block filter-label-font-size">
                    Recipe
                  </label> */}
                  <div className="d-flex justify-content-between align-items-center">
                    <label className="form-control-label textWhite filter-label-font-size">
                      Recipe
                    </label>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={filterState.recipeExclude}
                          onChange={handleToggleSwitch}
                          name="recipeExclude"
                          color="primary"
                          size="small"
                        />
                      }
                      label="Exclude"
                    />
                  </div>
                  {recipeData === "Loading..." ? (
                    recipeData
                  ) : recipeData === "No Data Found" ? (
                    recipeData
                  ) : (
                    <Select
                      placeholder="Select Recipe..."
                      components={{
                        DropdownIndicator: (props) =>
                          DropdownIndicator(props, RecipeImage),
                      }}
                      name="recipe"
                      value={{ label: filterState.recipe }}
                      onChange={onChangeSelectValue}
                      styles={customStyles}
                      options={recipeData}
                    />
                  )}
                </FormGroup>
              </Col>
              {/* {true && ( */}
              {clientSubscription === "premium" && (
                <>
                  <Col xs={12} md={6} xl={4} xxl={2}>
                    <FormGroup className="mb-3">
                      {/* <label className="form-control-label textWhite d-block filter-label-font-size">
                        Product
                      </label> */}
                      <div className="d-flex justify-content-between align-items-center">
                        <label className="form-control-label textWhite filter-label-font-size">
                          Product
                        </label>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={filterState.productExclude}
                              onChange={handleToggleSwitch}
                              name="productExclude"
                              color="primary"
                              size="small"
                            />
                          }
                          label="Exclude"
                        />
                      </div>
                      {productData === "Loading..." ? (
                        productData
                      ) : productData === "No Data Found" ? (
                        productData
                      ) : (
                        <Select
                          placeholder="Select Product..."
                          components={{
                            DropdownIndicator: (props) =>
                              DropdownIndicator(props, ProductImage),
                          }}
                          name="product"
                          value={{ label: filterState.product }}
                          onChange={onChangeSelectValue}
                          styles={customStyles}
                          options={productData}
                        />
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6} xl={4} xxl={2}>
                    <FormGroup className="mb-3">
                      {/* <label className="form-control-label textWhite d-block filter-label-font-size">
                        Brand
                      </label> */}
                      <div className="d-flex justify-content-between align-items-center">
                        <label className="form-control-label textWhite filter-label-font-size">
                          Brand
                        </label>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={filterState.brandExclude}
                              onChange={handleToggleSwitch}
                              name="brandExclude"
                              color="primary"
                              size="small"
                            />
                          }
                          label="Exclude"
                        />
                      </div>
                      {brandData === "Loading..." ? (
                        brandData
                      ) : brandData === "No Data Found" ? (
                        brandData
                      ) : (
                        <Select
                          placeholder="Select Brand..."
                          components={{
                            DropdownIndicator: (props) =>
                              DropdownIndicator(props, BrandImage),
                          }}
                          name="brand"
                          value={{ label: filterState.brand }}
                          onChange={onChangeSelectValue}
                          styles={customStyles}
                          options={brandData}
                        />
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6} xl={4} xxl={2}>
                    <FormGroup className="mb-3">
                      {/* <label className="form-control-label textWhite d-block filter-label-font-size">
                        Tier
                      </label> */}
                      <div className="d-flex justify-content-between align-items-center">
                        <label className="form-control-label textWhite filter-label-font-size">
                          Tier
                        </label>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={filterState.tierExclude}
                              onChange={handleToggleSwitch}
                              name="tierExclude"
                              color="primary"
                              size="small"
                            />
                          }
                          label="Exclude"
                        />
                      </div>
                      {tierData === "Loading..." ? (
                        tierData
                      ) : tierData === "No Data Found" ? (
                        tierData
                      ) : (
                        <Select
                          placeholder="Select Tier..."
                          components={{
                            DropdownIndicator: (props) =>
                              DropdownIndicator(props, TierImage),
                          }}
                          name="tier"
                          value={{ label: filterState.tier }}
                          onChange={onChangeSelectValue}
                          styles={customStyles}
                          options={tierData}
                        />
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6} xl={4} xxl={2} className="mb-3">
                    <TimePickers
                      filtersData={filtersData}
                      handleDateRange={handleDateRange}
                      timeRange={timeRange}
                      setTimeRange={setTimeRange}
                    />
                  </Col>{" "}
                </>
              )}
              <Col
                className="justify-content-end align-items-center d-flex pt-4 mb-3 ml-auto"
                xs={12}
                sm={6}
                md={6}
                lg={4}
                xl={4}
                xxl={2}
              >
                <Button
                  style={{ backgroundColor: "#a7ce39", border: "none" }}
                  onClick={handleApply}
                >
                  Apply
                </Button>
                <Button
                  className=""
                  color="secondary"
                  onClick={() => {
                    const today = new Date();
                    const yesterday = new Date(today);
                    yesterday.setDate(yesterday.getDate() - 7);
                    setFilterState({
                      start_date: yesterday.toISOString(),
                      end_date: today.toISOString(),
                      recipe: "All",
                      line: "All",
                      product: "All",
                      brand: "All",
                      tier: "All",
                    });
                    handleApplyFilters({
                      start_date: yesterday.toISOString(),
                      end_date: today.toISOString(),
                      recipe: "All",
                      line: "All",
                      product: "All",
                      brand: "All",
                      tier: "All",
                    });
                  }}
                >
                  Clear
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
}

export default Filters;
