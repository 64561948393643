import React, { createContext, useEffect, useState } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { Container } from "reactstrap";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import routes from "routes.js";
import axios from "axios";
import { baseUrl } from "constants/url";
import authHeader from "services/auth-header";
import sortArray from "utils/sort";
import { useAuth } from "contexts/AuthContext";

export const DataContext = createContext();

const Admin = (props) => {
  const location = useLocation();
  const { user } = useAuth();
  const [clientName, setClientName] = useState(
    user?.clientName ? user.clientName : ""
  );
  const [clientId, setClientId] = useState("");
  const [clientSubscription, setClientSubscription] = useState(
    user?.subscriptionLabel ? user.subscriptionLabel : ""
  );
  const [lineData, setLineData] = useState("Loading...");

  const getLocalDayBounds = () => {
    const today = new Date();
    const offset = today.getTimezoneOffset() * 60000; // Convert offset to milliseconds

    const startOfDay = new Date(today);
    startOfDay.setHours(0, 0, 0, 0);
    const startISO = new Date(startOfDay.getTime() - offset).toISOString();

    const endOfDay = new Date(today);
    endOfDay.setHours(23, 59, 59, 999);
    const endISO = new Date(endOfDay.getTime() - offset).toISOString();

    return { startISO, endISO };
  };

  let today1 = new Date();
  today1.setHours(0, 0, 0, 0);

  let today2 = new Date();
  today2.setHours(23, 59, 59, 999);

  const [filtersData, setFiltersData] = useState({
    start_date: getLocalDayBounds().startISO,
    end_date: getLocalDayBounds().endISO,
    recipe: "All",
    recipeExclude: false,
    line: "All",
    lineExclude: false,
    kpiMin: 0,
    kpiMax: 1000,
    searchByRecipe: "",
    searchByRecipeExclude: false,
    product: "All",
    productExclude: false,
    brand: "All",
    brandExclude: false,
    tier: "All",
    tierExclude: false,
  });
  const [timeRange, setTimeRange] = useState({
    start_time: "",
    end_time: "",
  });

  React.useEffect(() => {
    if (location.pathname.includes("/admin/index")) {
      setClientName(user?.clientName ? user.clientName : "");
      setClientId(user?.role === "admin" ? "" : user?.clientId);
    }
  }, [location.pathname]);
  // React.useEffect(() => {
  //   if (location.pathname.includes('/admin/view_data')) {
  //     setClientName(user?.clientName ? user.clientName : '')
  //     setClientId(user?.role === 'admin' ? "" : user?.clientId)
  //   }
  // }, [location.pathname])

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => (
              <prop.component
                {...props}
                setClientName={setClientName}
                clientId={clientId}
                setClientId={setClientId}
                clientSubscription={clientSubscription}
                setClientSubscription={setClientSubscription}
              />
            )}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  useEffect(() => {
    if (!clientId) return;
    axios
      .get(`${baseUrl}/api/get_lookup_data/line/${clientId}`, {
        headers: authHeader(),
      })
      .then((res) => {
        let sortedData = sortArray(res.data.data);
        setLineData(sortedData);
      })
      .catch((err) => {
        console.log(err);
        setLineData("No Data Found");
      });
  }, [clientId]);

  const handleApplyFilters = (filterData) => {
    if (filterData.searchByRecipe) {
      setFiltersData({ ...filterData, recipe: "All" });
    } else {
      setFiltersData({ ...filterData });
    }
  };

  return (
    <DataContext.Provider
      value={{
        lineData,
        filtersData,
        setFiltersData,
        timeRange,
        setTimeRange,
        handleApplyFilters,
      }}
    >
      <>
        {user && (
          <div
            className="main-content min-vh-100 pb-7"
            style={{ backgroundColor: "#19312e" }}
          >
            <AdminNavbar
              {...props}
              clientName={clientName}
              setClientName={setClientName}
              setClientId={setClientId}
            />
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/admin/index" />
            </Switch>
            <Container fluid className="position-absolute bottom-0">
              <AdminFooter />
            </Container>
          </div>
        )}
      </>
    </DataContext.Provider>
  );
};

export default Admin;
